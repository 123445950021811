<template>
  <div>
    <ReportCenterTitle v-if="!inside" title="quizStatistics" :count="count" />
    <h6 class="mt-2" v-else>
      <router-link to="/quiz-statistics"
        >{{ $t("quizStatistics") }} ({{ count }})</router-link
      >
    </h6>
    <Table
      :hideHeader="inside"
      :count="count"
      :headers="headers"
      no-search
      :loading="isLoading"
      :items="items"
      no-card
    >
      <template #fullname="{ item }">
        <div>{{ item.fullname }}</div>
        <div>
          <small class="text-muted">
            <div>{{ $t("lastAction") }}: {{ formatter(item.createdAt) }}</div>
          </small>
        </div>
      </template>
      <template #videoDescription="{ item }">
        <div>{{ item.videoDescription }}</div>
        <div>
          <small class="text-muted">
            {{ item.sectionName }} > {{ item.productTitle }}
          </small>
        </div>
      </template>
    </Table>
    <div v-if="!inside" class="d-flex justify-content-center">
      <BoostrapPagination
        @change="SET_PAGINATION"
        :page="page"
        :pages="pages"
      ></BoostrapPagination>
    </div>
  </div>
</template>

<script>
import ReportCenterTitle from "../Title.vue";
import Table from "../../Tables/index.vue";
import { formatter } from "gurhan/helpers/Date";
import { Currency } from "gurhan/helpers/Currency";
import BoostrapPagination from "gurhan/vue/BootstrapPagination.vue";

export default {
  components: {
    BoostrapPagination,
    ReportCenterTitle,
    Table,
  },
  props: {
    inside: {
      default: false,
      type: Boolean,
    },
    limit: {
      default: 10,
    },
  },
  data() {
    return {
      items: [],
      isLoading: true,
      count: 0,
      page: 1,
      headers: [
        {
          title: this.$t("user"),
          value: "fullname",
          col: 12,
          md: 4,
          lg: 3,
          xl: 3,
        },
        {
          title: this.$t("quizName"),
          value: "quizName",
          col: 12,
          md: 2,
          lg: 2,
          xl: 2,
        },
        {
          title: this.$t("videoTitle"),
          value: "videoDescription",
          col: 12,
          md: 3,
          lg: 3,
          xl: 3,
        },
        {
          title: this.$t("productName"),
          value: "productTitle",
          col: 12,
          md: 2,
          lg: 2,
          xl: 2,
        },
        {
          title: this.$t("quizCount"),
          value: "count",
          col: 12,
          md: 2,
          lg: 2,
          xl: 2,
        },
      ],
    };
  },
  computed: {
    pages() {
      return Math.ceil(this.count / 10);
    },
    getSettings() {
      return this.$store.state.User.settings;
    },
  },
  methods: {
    formatter,
    Currency,
    toHHMMSS(secs) {
      var sec_num = parseInt(secs, 10);
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor(sec_num / 60) % 60;
      var seconds = sec_num % 60;

      return [hours, minutes, seconds]
        .map((v) => (v < 10 ? "0" + v : v))
        .filter((v, i) => v !== "00" || i > 0)
        .join(":");
    },
    courseRateClass(rate) {
      if (rate > 85) return "text-success";
      else if (rate > 70) return "text-info";
      else if (rate > 50) return "text-warning";
      else return "text-danger";
    },
    SET_PAGINATION(page) {
      this.page = page;
      this.$router.replace({ query: { page } });
      this.GET_ITEMS();
    },
    async GET_ITEMS() {
      this.isLoading = true;
      const response = await this.$api.get(
        `Quizzes/QuizStatistics?page=${this.page}&limit=${this.limit}`
      );
      if (response.message === "OK") {
        this.items = response.data.items;
        this.count = response.data.count;
      }
      this.isLoading = false;
    },
  },
  mounted() {
    const q = this.$route.query;
    if (!isNaN(Number(q.page))) this.page = Number(q.page);
    this.GET_ITEMS();
  },
};
</script>

<style></style>
